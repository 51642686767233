// app/javascript/controllers/select_callback_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "showHide"];

  connect() {
    if (this.hasSelectTarget && this.hasShowHideTarget) {
      this.updateVisibility();
      this.selectTarget.addEventListener("change", () => this.updateVisibility());
    }

    // Add a listener to the form's submit event to clean up hidden fields
    const form = this.element.closest('form');
    if (form) {
      form.addEventListener('submit', this.removeHiddenFields.bind(this));
    }
  }

  updateVisibility() {
    const selectedValue = this.selectTarget.value;
    this.showHideTargets.forEach((target) => {
      const valueToShowFor = target.dataset.showFor;
      if (valueToShowFor === selectedValue) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    });
  }

  removeHiddenFields(event) {
    // Remove all hidden elements from the form
    this.showHideTargets.forEach((target) => {
      if (target.classList.contains("hidden")) {
        target.querySelectorAll("input, select").forEach((input) => {
          input.remove();
        });
      }
    });
  }

  disconnect() {
    if (this.hasSelectTarget) {
      this.selectTarget.removeEventListener("change", this.updateVisibility);
    }
  }
}
